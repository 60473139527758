import styled from 'styled-components';
import ProfileTemplate from 'components/templates/ProfileTemplate';
import ItemsList from 'components/molecules/ItemsList';

const Message = styled.div`
  margin: 1em auto;
  text-align: center;
  max-width: 20em;
  font-style: italic;
`;

const ProfileBookmarksPage = ({ currentUser }) => (
  <ProfileTemplate>
    {currentUser.bookmarks.length === 0 && <Message>Нет закладок</Message>}
    <ItemsList items={currentUser.bookmarks.map((bookmark) => bookmark.item)} />
  </ProfileTemplate>
);

export default ProfileBookmarksPage;
